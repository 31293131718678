.carousel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.carousel-close-button {
    position: absolute;
    top: 20px;
    left: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1001;
    padding: 0;
}

.carousel-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prev-button, .next-button {
    background: none;
    border: none;
    color: white;
    font-size: 3rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
}

.prev-button {
    left: 20px;
    padding: 0;
}

.next-button {
    right: 20px;
    padding: 0;
}

.media-display {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 786px) {
    .prev-button {
        left: 5px;
    }

    .next-button {
        right: 5px;
    }
}


