@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: #000; 
    height: 80px;
    padding: 0 10px;
    overflow: hidden;
    z-index: 3;
}

.header img {
    height: 300px;
    width: auto;
    margin-left: -100px;
}

.header nav a {
    margin-right: 40px;
    color: white; 
    text-decoration: none; 
    font-family: "Inter", sans-serif;
    font-size: larger;
    font-weight: bold;
}

.toggle-button {
    display: none;
    font-size: 30px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
}

.sidebar {
    position: fixed;
    top: 0;
    right: -250px; /* Hide sidebar offscreen initially */
    width: 250px;
    height: 100%;
    background-color: #000;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: right 0.3s ease-in-out;
    z-index: 1000; /* Ensure sidebar is on top */
    padding-top: 80px;
}

.sidebar.open {
    right: 0; /* Slide in the sidebar */
}

.sidebar a {
    color: white;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-size: larger;
    font-weight: bold;
    margin: 10px 0;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

@media (max-width: 600px) {
    .header img {
        height: 200px; /* Reduced height for mobile */
        margin-left: -100px;
    }

    .header nav {
        display: none; /* Hide nav links on mobile */
    }

    .toggle-button {
        display: block; /* Show toggle button on mobile */
        padding: 0;
    }
}
