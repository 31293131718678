.custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 100%;
}

.custom-button.default-bg {
    background: linear-gradient(to bottom, #f4c27a, #c09342);
    color: black;
    border: none;
}

.custom-button.transparent {
    background-color: black;
    color: white;
    border: none;
}

.custom-button.loading {
    background-color: black;
}

.custom-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.custom-button:disabled {
    pointer-events: none;
}

.custom-button svg {
    display: inline-block;
}
