.login-container {
    display: flex;
    min-height: 100vh; 
    width: 100%; /* Ensure it takes the full width of the viewport */
    overflow: hidden; /* Prevents scroll bars from appearing */
}

.login-image {
    flex: 1;
    background-size: cover;
    background-position: center;
}

.login-form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: black;
    color: #FFFFFF;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.login-logo {
    width: 100%; /* Ensure the logo width is responsive */
    max-width: 500px; 
    margin-top: -250px;  /* Adjust the top margin to fit the logo correctly */
}

form {
    width: 100%;  
    max-width: 500px;  /* Limit the form width on larger screens */
    text-align: center;
    margin-top: 50px;  /* Space above the form */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.login-form-container h2 {
    margin-bottom: 0;
}

.login-form-container p {
    color: #706F6C; /* Dark gray color for the paragraph text */
    margin-top: 5px;
}

.vertical-btn-group {
    width: 100%;  /* Button group takes full width of the form */
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons horizontally */
}

.vertical-btn-group .btn {
    width: 80%;  /* Buttons occupy 80% of their container width */
    font-size: 18px;
    padding: 10px 20px;
    margin-top: 10px;  
    border-radius: 10px;
    border: 2px solid transparent; /* Default border to maintain layout */
    cursor: pointer;
}

.input-group {
    width: 100%;  /* Button group takes full width of the form */
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons horizontally */
}

.input-group .form-input{
    width: 71%;  /* Button group takes full width of the form */
    height: 20px;
    font-size: 14px;
    padding: 10px 20px;
    margin-top: 10px;  
    border-radius: 10px;
    border: 2px solid #706F6C; 
    background-color: black;
    color: #706F6C;
    outline: none;
}

.btn-host.active,
.btn-guest.active {
    color: black; /* Text color for visibility */
    border-color: #F6C67C; /* Same as background */
    background: linear-gradient(90deg, #F6C67C 0%, #A58447 100%);
}

.btn-host.inactive,
.btn-guest.inactive {
    background-color: transparent; /* No fill for inactive */
    color: #F6C67C; /* Text color for visibility */
    border: 1px solid;
}

.form-input.active {
    border-color: #F6C67C; /* Change border color when active or focused */
    color: white;
}

/* Ensure no traces of previous border when inactive */
.form-input.inactive {
    border-color: #706F6C; /* Maintain consistent inactive border color */
}

.btn-confirm.active {
    border-color: #F6C67C; /* Active border and background color */
    background: linear-gradient(90deg, #F6C67C 0%, #A58447 100%);
    color: black;
}

.btn-confirm.inactive {
    border-color: #706F6C; /* Inactive border color */
    background-color: #706F6C;
    color: black;
}

/* Mobile layout adjustments */
@media (max-width: 768px) {

    .login-container {
        flex-direction: column;
        height: 100vh;
    }

    .login-image {
        display: none; /* Hide the image section */
    }

    .login-form-container {
        width: 100%; /* Take full width of the screen */
        padding: 10px; /* Reduce padding */
        flex: none;
    }

    .login-logo {
        width: 150%; /* Adjust logo size for mobile */
        margin-top: -150px; /* Reset margin top */
    }

    form {
        margin-top: 20px; /* Reduce space above the form */
    }

    .vertical-btn-group .btn {
        width: 90%; /* Buttons occupy 90% of their container width */
        font-size: 16px; /* Adjust font size for mobile */
        padding: 10px; /* Adjust padding for mobile */
    }

    .input-group .form-input {
        width: 78%; /* Input field occupies 90% of its container */
    }
}
