.text-input {
    width: 100%;
    /*max-width: 400px;*/
    padding: 12px 16px;
    margin: 8px 0;
    /*font-size: 16px;*/
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.text-input:focus {
    border-color: #007bff; /* Highlight color for focused input */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Button styles */
.create-profile-button {
    /*width: 100%;*/
    /*max-width: 400px;*/
    padding: 12px 16px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff; /* Primary button color */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.create-profile-button:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
}

.create-profile-button:active {
    background-color: #004085; /* Even darker shade for active state */
    transform: scale(0.98); /* Slight shrink effect on click */
}

/* Ensure the container aligns the inputs and button properly */
.input-div {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}