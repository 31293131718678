.media-icons {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 15px;
    font-size: 2rem;
    cursor: pointer;
    color: white;
}

.media-render-icons {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 15px;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
    z-index: 2;
}

a { color: white; }
  

  
