html{
    scroll-snap-type: y mandatory;
}

.page-two-content {
    display: flex;
    flex-direction: column;
    background-color: #000; /* Consistent black background */
    color: white;
    padding: 0px; /* Remove any padding that might affect the alignment */
}

.header{
    position: sticky;
    top: 0;
    z-index: 3;
}

.divider {
    height: 2px;
    background-color: #555; 
    position: sticky;
    top: 80px
}

.page-two-container,
.page-three-container {
  scroll-snap-align: start;
}