/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lora:wght@400;500&display=swap');

.page-one-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.page-one-image {
    width: 100%;
    height: 70vh;  /* Explicitly set to 75% of viewport height */
    object-fit: cover;
    display: block;
}

.page-one-content {
    width: 100%;
    height: 30vh;  /* Explicitly set to 25% of viewport height */
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.page-one-title {
    font-family: "Playfair Display", serif;
    font-size: clamp(24px, 4vw, 60px);
    letter-spacing: 0.2vw;
    font-weight: 700;
    color: #D4AF37;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
}

.page-one-date,
.page-one-photographer {
    margin: 5px 0;
    font-family: "Lora", serif;
    /*font-size: clamp(16px, 2vw, 20px);*/
    font-weight: 500;
    color: #D4AF37;
    text-align: center;
    /*white-space: nowrap;*/
    /*overflow: visible;*/
    /*text-overflow: inherit;*/
    max-width: 90%;
}

#arrow-gif {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
}

@media (min-width: 768px) {
    .page-one-title {
        letter-spacing: 3px;
    }

    #arrow-gif {
        bottom: 15px;
        width: 50px;
        height: 50px;
    }
}