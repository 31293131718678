.row-image-container {
    width: 100%;
    height: 100%; 
    position: relative; 
    overflow: hidden; 
    padding: 0%;
    margin: 0%;
}

.carousel-image{
    max-width: 100vw;
    max-height: 85vh;
}

.image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0; /* Light gray background as fallback */
    overflow: hidden;
}

.shimmer-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
}

.shimmer {
    width: 100%;
    height: 100%;
    background: linear-gradient(
            90deg,
            #f0f0f0 25%,
            #e0e0e0 50%,
            #f0f0f0 75%
    );
    background-size: 200% 100%;
    animation: shimmer-animation 1.5s infinite;
}

.lazy-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.lazy-image.visible {
    opacity: 1;
}

@keyframes shimmer-animation {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}