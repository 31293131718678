.albums {
    text-align: center;
    background-color: black;
    min-height: 100vh; /* Ensures the albums container takes at least the full height of the viewport */
    color: white; /* Ensures text is visible against the black background */
}

.cover-photo-container {
    position: relative;
    width: 100%;
    height: 150px; /* Set height to match the original image height */
    overflow: hidden;
}

.cover-photo-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1),rgba(0, 0, 0, 0.5));
}

.album-name {
    position: absolute;
    bottom: 10px; /* Position at the bottom of the image */
    left: 50%;
    transform: translateX(-50%);
    color: #f4c27a;
    padding: 10px;
    text-align: center; /* Center the text */
    z-index: 2;
    /*font-size:x-large;*/
    font-family: 'Montserrat', sans-serif; /* Or another font of choice */
    font-weight: bold;
    white-space: nowrap;

}

.album-content {
    padding: 5px;
}

.scroll-to-top-gif {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s, opacity 0.3s, filter 0.3s;
    opacity: 0.5;
}

.scroll-to-top-gif:hover {
    opacity: 1;
    transform: scale(1.2); /* Zoom in effect */
    filter: brightness(1.5); /* Brightness effect */
}

.albums {
    text-align: center;
    background-color: black;
    min-height: 100vh; /* Ensures the albums container takes at least the full height of the viewport */
    color: #f4c27a; /* Ensures text is visible against the black background */
}

.cover-photo-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1),rgba(0, 0, 0, 0.5));
}

.album-content {
    padding: 5px;
}

.scroll-to-top-gif {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s, opacity 0.3s, filter 0.3s;
    opacity: 0.5;
}

.scroll-to-top-gif:hover {
    opacity: 1;
    transform: scale(1.2); /* Zoom in effect */
    filter: brightness(1.5); /* Brightness effect */
}

/* Mobile layout adjustments */
@media (max-width: 768px) {
    .cover-photo-container {
        height: 100px; /* Reduce height for mobile */
    }

    .album-name {
        /*font-size: x-large; !* Reduce font size for mobile *!*/
        padding: 5px; /* Adjust padding for mobile */
        white-space: nowrap;
    }

    .scroll-to-top-gif {
        width: 30px; /* Adjust size for mobile */
        height: 30px; /* Adjust size for mobile */
        bottom: 20px; /* Adjust position for mobile */
        right: 20px; /* Adjust position for mobile */
    }
}

.selection-toolbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px 20px 20px 20px;
    display: flex;
    align-items: center;
    gap: 16px;
    z-index: 1000;
}

.selection-toolbar button {
    background: #f4c27a;
    color: black;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.selection-toolbar button:hover {
    background: #f5d4a3;
}

.selection-toolbar span {
    color: white;
}

.media-wrapper.selected,
.media-half.selected,
.media-quarter.selected {
    filter: brightness(0.6);
}

.toolbar-icons {
    display: flex;
    gap: 16px;
    margin-left: auto; /* Pushes the icons to the right */
}

.icon-album {
    font-size: 24px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-modal {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
}

.progress-modal h3 {
    margin: 0 0 10px;
}

.progress-bar {
    width: 100%;
    height: 15px;
    background-color: #black;
    border-radius: 7.5px;
    overflow: hidden;
    margin: 10px 0;
}

.progress-bar-fill {
    height: 100%;
    background-color: #f4c27a;
    width: 0%;
    transition: width 0.2s ease-in-out;
}

.progress-modal p {
    margin: 0;
    font-size: 14px;
    color: #555;
}
