.gallery-container {
    /*max-width: 1200px;*/
    width: 100%;
    /*margin: 0 auto;*/
    /*padding: 0 20px;*/
}

.gallery-section {
    margin-bottom: 8px;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 8px;
}

.featured-image {
    grid-column: span 8;
    grid-row: span 2;
}

.side-images {
    grid-column: span 4;
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 8px;
    grid-row: span 2;
}

.bottom-row {
    grid-column: span 12;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
/*    margin-top: 8px;*/
}

.media-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.media-wrapper.featured {
    aspect-ratio: 4/3;
}

.media-wrapper.small {
    aspect-ratio: 3/2;
}

.media-wrapper.selected {
    outline: 2px solid #3b82f6;
    outline-offset: 2px;
}

.media-wrapper img,
.media-wrapper video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /*border-radius: 8px;*/
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .gallery-grid {
        grid-template-columns: 1fr;
    }

    .featured-image,
    .side-images,
    .bottom-row {
        grid-column: span 1;
    }

    .side-images {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
    }
}