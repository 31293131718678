/* Video container */
.video-container {
    position: relative;
    width: 100%;
    height: 100%;
    /*border-radius: 10px;*/
    overflow: hidden;
}

/* Play/Pause icon */
.play-pause-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 100px; /* Increased font size */
    cursor: pointer;
    color: white; /* Black color for icons */
    opacity: 1;
    z-index: 2;
}

.play-pause-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

.video-container-carousel {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-video {
    max-width: 70vw;
    max-height: 50vw;
    object-fit: contain;
}
