@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lora:wght@400;500&display=swap');


.login-container {
    display: flex;
    min-height: 100vh;
    background-color: #000000;
}

.image-div {
    width: 60%;
    position: relative;
    overflow: hidden;
}

.image-div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.event-details-div {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    height: 50%;
    color: #ffffff;
}

.event-details-div img {
    width: 80%;
    height: 50px;
    object-fit: cover;
    margin-bottom: 1rem;
    object-position: center;
}

.login-form-container {
    margin-top: 2rem;
    width: 100%;
    max-width: none;
    text-align: center;
}

.event-name {
    font-size: 28px;
    letter-spacing: 3px;
    font-family: "Playfair Display", serif;
    background: linear-gradient(90deg, #F6C67C 0%, #A58447 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #F6C67C;
    margin-bottom: 0.5rem;
}

.event-date {
    font-size: 16px;
    background: linear-gradient(90deg, #F6C67C 0%, #A58447 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #F6C67C;
}

.auth-divider {
    width: 250px !important; /* Added !important to override SVG's width */
    height: 15px !important; /* Added explicit height */
    margin: 1.5rem auto;
    display: block;
}

/* Remove any conflicting styles */
.login-form-container img {
    width: auto; /* Reset general image styling */
    height: 50px;
    object-fit: contain;
    object-position: center;
}

.page-title {
    font-size: 24px;
    font-family: "Lora", serif;
    font-weight: bold;
    color: #FFFFFF;
}

.page-description {
    font-size: 16px;
    margin-top: 5px;
    color: #706F6C;
}

.phone-input-div {
    margin-top: 2rem;
    width: 60%;
}

.phone-input {
    background: transparent !important;
    color: white !important;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    padding: 0.75rem 1rem;
    /*width: 100%;*/
    margin-bottom: 1rem;
    display: flex;
    align-items: center; /* Align items vertically */
}

/* Style for the country select container */
.PhoneInputCountry {
    min-width: 40px; /* Give enough space for the flag and code */
    display: flex;
    align-items: center;
}

/* Style for the flag */
.PhoneInputCountryIcon {
    width: 25px;
    height: 10px;
}

.PhoneInputCountryIconImg {
    height: 18px !important;
}

/* Style for the country select dropdown */
.PhoneInputCountrySelect {
    background-color: #161619 !important;
    color: white !important;
    border: none;
    width: auto;
}

/* Style for the input field */
.PhoneInputInput {
    background: transparent !important;
    color: white !important;
    border: none !important;
    outline: none !important;
    padding: 0;
    flex: 1; /* Take remaining space */
    min-width: 0; /* Prevent overflow */
}

/* Custom button styling */
.send-code-button {
    width: 100%;
    padding: 0.75rem;
    /*background: linear-gradient(to right, #D4AF37, #FFD700);*/
    background: linear-gradient(90deg, #F6C67C 0%, #A58447 100%);
    color: black;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.2s;
}

.send-code-button:hover {
    opacity: 0.9;
}

/* Responsive design */
@media (max-width: 1024px) {
    .login-container {
        flex-direction: column;
        height: 100vh;
    }

    .image-div {
        width: 100%;
        height: 50%;
        object-fit: cover;
    }

    .event-details-div {
        height: 50%;
        width: 100%;
    }

    .event-details-div #logo{
        display: none;
    }

    .event-name {
        /*margin-top: 0.5rem;*/
        font-size: 24px;
    }

    .event-date {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .page-title {
        font-size: 18px;
    }

    .page-description {
        font-size: 14px;
    }

    .login-form-container {
        margin-top: 0;
    }

    .auth-divider {
        width: 80%;
        height: 20px;
        margin-top: 0;
        /*object-fit: cover;*/
        object-position: center;
    }
}