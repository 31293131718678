.physical-albums {
    text-align: center;
    background-color: black;
    min-height: 100vh; /* Ensures the albums container takes at least the full height of the viewport */
    color: #f4c27a; /* Ensures text is visible against the black background */
}


.heading {
    text-align: center;
    margin: 20px 0;
    color: white;
}
  
.scroll-to-top-gif {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s, opacity 0.3s, filter 0.3s;
    opacity: 0.5;
}

.scroll-to-top-gif:hover {
    opacity: 1;
    transform: scale(1.2); /* Zoom in effect */
    filter: brightness(1.5); /* Brightness effect */
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-modal {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
}

.progress-modal h3 {
    margin: 0 0 10px;
}

.progress-bar {
    width: 100%;
    height: 15px;
    background-color: #black;
    border-radius: 7.5px;
    overflow: hidden;
    margin: 10px 0;
}

.progress-bar-fill {
    height: 100%;
    background-color: #f4c27a;
    width: 0%;
    transition: width 0.2s ease-in-out;
}

.progress-modal p {
    margin: 0;
    font-size: 14px;
    color: #555;
}