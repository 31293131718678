@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.page-two-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: calc(100vh - 80px); /* Adjusted calculation for height */
    width: 100%;
    position: relative; /* Added position relative for proper positioning of child elements */
}

.page-two-container {
    display: flex;
    flex-grow: 1;
    padding: 20px;
    align-items: center; /* Centering content vertically */
    justify-content: center; /* Centering content horizontally */
    text-align: center;
    gap: 10px;
    height: 100%; /* Ensure the container takes full height */
}

.goldbg {
    height: 4px;
    width: 50%;
    background: linear-gradient(90deg, #f6c67c 0%, #a58447 100%);
}

.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%; /* Ensure the image container takes full height */
}

.image-second {
    object-fit: cover;
    width: 100%;
    height: 65vh;
    /*border-radius: 20px;*/
}

.description-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content */
    padding-top: 20px; /* Adds padding to ensure spacing from the top */
    font-family: "Poppins", sans-serif;
    height: 100%; /* Ensure the description container takes full height */
}

.description-heading {
    margin-bottom: 20px;
    font-size: 50px;
    font-weight: bold;
}

.description-container p {
    color: #e1e1e1;
    padding-left: 20px; /* 5px on small screens, 0px on large screens */
    padding-right: 20px; /* 5px on small screens, 0px on large screens */
    text-align: center;
    margin-top: 40px; /* 10px on small screens, 20px on large screens */
    margin-bottom: 40px; /* 10px on small screens, 20px on large screens */
    font-size: 1rem; /* Default text size */
    line-height: 30px;
}

#arrow-gif-page2 {
    position: relative; /* Ensure it is positioned relative to the wrapper */
    bottom: 20px; /* Position from the bottom of the page */
    left: 50%;
    transform: translateX(-50%);
    width: 70px;
    height: 70px;
}

@media (max-width: 768px) {
    .image-container {
        display: none; 
    }

    .description-container {
        padding-top: 0px;
        justify-content: center; /* Center content vertically */
    }

    .description-heading {
        font-size: 36px;
    }

    .description-container p {
        padding-left: 20px; /* 5px on small screens, 0px on large screens */
        padding-right: 20px; /* 5px on small screens, 0px on large screens */
        text-align: center;
        margin-top: 40px; /* 10px on small screens, 20px on large screens */
        margin-bottom: 40px; /* 10px on small screens, 20px on large screens */
        font-size: 1rem; /* Default text size */
        line-height: 30px;
    }

    #arrow-gif-page2 {
        bottom: 10px; /* Adjust position for mobile */
    }
}

@media (max-width: 480px) {
    .image-container {
        display: none; 
    }

    .description-container {
        padding-top: 0px;
        justify-content: center; /* Center content vertically */
    }

    .description-heading {
        font-size: 30px;
    }

    .description-container p {
        padding-left: 20px; /* 5px on small screens, 0px on large screens */
        padding-right: 20px; /* 5px on small screens, 0px on large screens */
        text-align: center;
        margin-top: 40px; /* 10px on small screens, 20px on large screens */
        margin-bottom: 40px; /* 10px on small screens, 20px on large screens */
        font-size: 1rem; /* Default text size */
        line-height: 30px;
    }

    #arrow-gif-page2 {
        bottom: 10px; /* Adjust position for mobile */
    }
}
