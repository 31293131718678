.page-three-container {
    color: white;
    background-color: black; 
    height: calc(100vh - 80px); /* Full height minus navbar height */
    padding-top: 50px; /* Push content below navbar */
    overflow:auto;
}

.container {
    margin-left: 15px;
    margin-right: 15px;
    padding: 20px;
    width: auto; 
    max-width: 100%;
}

.gallery-heading {
    text-align: center; 
    margin-bottom: 20px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card {
    flex: 0 0 auto;
    background-color: #444; 
    border: none; 
    margin: 25px; 
}

.custom-card {
    background-size: cover;
    background-position: center;
    height: 300px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 25px;
    transition: transform 0.3s ease; /* Smooth transitions for hover effects */
    cursor: pointer;
    position: relative; /* Position relative to allow absolute positioning within */
    overflow: hidden; /* Ensures no overflow outside the border radius */
}

.custom-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0); /* Semi-transparent black overlay */
    transition: background-color 0.3s ease; /* Transition for hover effect */
}

.custom-card:hover {
    transform: scale(1.03); /* Enlarges the card on hover */
}

.card-title {
    color: white; /* White color for better visibility */
    background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black background */
    font-size: 16px; /* Adjust font size as necessary */
    font-weight: bold; /* Makes the title bold */
    margin: 10px; /* Removes any default margin */
    width: auto; /* Ensures the title spans the width needed */
    padding: 10px 10px; /* Padding for better spacing */
    position: absolute; /* Position absolute to place at bottom left */
    bottom: 0px; /* Adjust distance from the bottom */
    left: 0px; /* Adjust distance from the left */
    border-radius: 25px; /* Adds rounded corners */
    transition: filter 0.3s ease; /* Smooth transition for title brightness */
}

.custom-card:hover .card-title {
    filter: brightness(2); /* Increases brightness of the title on hover */
}

.lock-icon {
    margin-right: 8px; /* Adds space to the right of the lock icon */
}

/* Hide scrollbar for all elements */
* {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

*::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

@media (max-width: 768px) {
    .col-sm-12 {
        flex: 0 0 100%;
        width: 100%;
    }
    .col-sm-6 {
        flex: 0 0 100%;
        width: 100%;
    }

    .card {
        margin-bottom: 5px;
        margin-right: 0;
        margin-left: 0;
    }

    .custom-card {
        height: 125px;
        /*width: 100%;*/
    }

    .custom-card::before {
        height: 125px;
        width: 100%;
    }
}

@media (min-width: 992px) {
    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; 
    }
    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%; 
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
