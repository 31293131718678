html{
    scroll-snap-type: y mandatory;
}

.page {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.page-one-container,
.page-two-content {
  scroll-snap-align: start;
}


.hidden {
    top: 100vh;
} 