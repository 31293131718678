@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lora:wght@400;500&display=swap');


.otp-login-container {
    display: flex;
    min-height: 100vh;
    background-color: #000000;
}

.otp-image-div {
    width: 60%;
    position: relative;
    overflow: hidden;
}

.otp-image-div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.otp-event-details-div {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    height: 50%;
    color: #ffffff;
}

.otp-event-details-div img {
    width: 80%;
    height: 50px;
    object-fit: cover;
    margin-bottom: 1rem;
    object-position: center;
}

.otp-login-form-container {
    margin-top: 2rem;
    width: 100%;
    max-width: none;
    text-align: center;
}

.otp-event-name {
    font-size: 28px;
    letter-spacing: 3px;
    font-family: "Playfair Display", serif;
    background: linear-gradient(90deg, #F6C67C 0%, #A58447 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #F6C67C;
    margin-bottom: 0.5rem;
}

.otp-event-date {
    font-size: 16px;
    background: linear-gradient(90deg, #F6C67C 0%, #A58447 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #F6C67C;
}

.otp-auth-divider {
    width: 250px !important; /* Added !important to override SVG's width */
    height: 15px !important; /* Added explicit height */
    margin: 1.5rem auto;
    display: block;
}

/* Remove any conflicting styles */
.otp-login-form-container img {
    width: auto; /* Reset general image styling */
    height: 50px;
    object-fit: contain;
    object-position: center;
}

.otp-page-title {
    font-size: 24px;
    font-family: "Lora", serif;
    font-weight: bold;
    color: #FFFFFF;
}

.otp-page-description {
    font-size: 16px;
    margin-top: 5px;
    color: #706F6C;
}

.code-inputs {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    margin-bottom: 2rem;
}

#otp-input {
    width: 40px;
    height: 40px;
    border: 1px solid #333;
    background-color: transparent;
    border-radius: 8px;
    text-align: center;
    color: white;
    font-size: 1rem;
    outline: none;
    margin: 1rem 0.2rem;
}

#otp-input:focus {
    border-color: #FFD700;
}

/* Custom button styling */
.otp-page-button {
    width: 100%;
    padding: 0.75rem;
    /*background: linear-gradient(to right, #D4AF37, #FFD700);*/
    background: linear-gradient(90deg, #F6C67C 0%, #A58447 100%);
    color: black;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.2s;
}

.otp-page-button:hover {
    opacity: 0.9;
}

/* Responsive design */
@media (max-width: 1024px) {

    #otp-input {
        width: 25px;
        height: 25px;
    }

    .otp-login-container {
        flex-direction: column;
        height: 100vh;
    }

    .otp-image-div {
        width: 100%;
        height: 60%;
        object-fit: cover;
    }

    .otp-event-details-div {
        height: 40%;
        width: 100%;
    }

    .otp-event-details-div #logo{
        display: none;
    }

    .otp-event-name {
        margin-top: 1rem;
        font-size: 24px;
    }

    .otp-event-date {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .otp-page-title {
        font-size: 18px;
    }

    .otp-page-description {
        font-size: 14px;
    }

    .otp-login-form-container {
        margin-top: 0;
    }

    .otp-auth-divider {
        width: 80%;
        height: 20px;
        margin-top: 0;
        /*object-fit: cover;*/
        object-position: center;
    }
}